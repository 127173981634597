<template>
    <b-container>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase mt-3"><strong>ANTES DE LA INDUCCIÓN A LA ANESTESIA</strong></p>
                <p class="txt_secondary">1. El paciente ha confirmado</p>
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">Su identidad</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">El sitio de su procedimiento</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">El procedimiento</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">Su concentimiento</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">2. Marcaje del sitio quirúrgico</p>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" sm="12" md="2" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Aplica</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="2" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No aplica</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">3. Control en la seguridad de la anestesia</p>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">Valor pre-anestésica</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="5" lg="4">
                        <span class="size-span">Pre-medicación anestésica</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-form-textarea placeholder="Indica" autocomplete="off" class="txtArea_border" size="sm" no-resize rows="1" max-rows="2"/>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <p class="txt_secondary">4. Quirófano informa: <small>(cuando solicita al paciente)</small></p>
                    </b-col>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="5">
                                <span class="size-span">Equipos electromédicos funcionando</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="5">
                                <span class="size-span">Recurso humano completo</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="5">
                                <span class="size-span">Material especial, implantes e instrumental completo</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm" />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <p class="txt_secondary">4. El paciente tiene:</p>
                    </b-col>
                    <b-col cols="12">
                        <b-row no-gutters>
                            <b-col class="d-flex" cols="12">
                                <b-form-checkbox size="sm" />
                                <span class="size-span">Alergias</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="d-flex" cols="12">
                                <b-form-checkbox size="sm" />
                                <span class="size-span">Equipo e instrumental de ayuda disponibles</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="d-flex" cols="12">
                                <b-form-checkbox size="sm" />
                                <span class="size-span">Riesgo de emorragia</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="d-flex" cols="12">
                                <b-form-checkbox size="sm" />
                                <span class="size-span">Disponibilidad de componentes sanguineos</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="d-flex" cols="12">
                                <b-form-checkbox size="sm" />
                                <span class="size-span">Se ha previsto la posibilidad de acceso intravenoso y líquidos adecuados <small>(Terapia endovenosa y solución parental correcta)</small></span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="12">
                                <span class="size-span">Cateter periférico calibre</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <span class="size-span">Otros</span>
                            </b-col>
                            <b-col class="mt-2" cols="12">
                                <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {

}
</script>